.react-datepicker-wrapper {
	display: inline-block;
	padding: 0;
	border: 0;
	width: 100%;
}

.react-datepicker {
	font-family: 'Nunito Sans' !important;
	font-size: 0.8rem;
	background-color: #fff;
	padding: 5px;
	color: #000;
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	display: inline-block;
	position: relative;
}

.react-datepicker__triangle {
	position: absolute;
	left: 50px;
}
.react-datepicker__aria-live{
	display:none;
}

.react-datepicker-popper {
	z-index: 1;
}

.react-datepicker__triangle {
	left: auto;
	right: 42px;
}
.react-datepicker__triangle {
	left: 42px;
	right: auto;
}

.react-datepicker__header {
	text-align: center;
	background-color: transparent;
	border-top-left-radius: 0.3rem;
	padding: 5px 0;
	position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 0.8rem * 1.18;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	align-items: center;
	background: none;
	display: flex;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 2px;
	padding: 0;
	border: none;
	z-index: 1;
	height: 2rem;
	width: 2rem;
	text-indent: -999em;
	overflow: hidden;
}

.react-datepicker__navigation-icon {
	position: relative;
	top: -1px;
	font-size: 20px;
	width: 0;
}

.react-datepicker__month-container {
	float: left;
}

.react-datepicker__year {
	margin: 0.4rem;
	text-align: center;
}

.react-datepicker__year-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}

.react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
}

.react-datepicker__month-text,
.react-datepicker__quarter-text {
	display: inline-block;
	width: 4rem;
	margin: 2px;
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-names {
	margin-bottom: -8px;
	background: #fff7ea;
	font-weight: 700;
	border-radius: 4px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 30px;
	line-height: 1.7rem;
	text-align: center;
	margin: 4px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
	cursor: pointer;
	border-radius: 4px;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day:hover {
	background-color: #ffe1a8;
	color: #000;
	font-weight: bold;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range {
	background-color: #ffe1a8;
	color: #000;
	font-weight: bold;
}

.react-datepicker__day--in-range:not(
		.react-datepicker__day--in-selecting-range
	) {
	background-color: #e6e6e6;
	color: #000;
	cursor: no-drop;
}

.react-datepicker__day--disabled {
	cursor: no-drop;
	color: #ccc;
}

.react-datepicker__day--disabled:hover {
	background-color: transparent;
	color: #ccc;
}

.react-datepicker__close-icon {
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	position: absolute;
	top: -1px;
	right: -5px;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.react-datepicker__close-icon::after {
	cursor: pointer;
	background-color: #ffe1a8;
	color: #fff;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	padding: 2px;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	content: '\00d7';
}

.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	margin: 5px 0 10px 15px;
	text-align: left;
}

.react-datepicker-time__caption {
	display: inline-block;
}

.react-datepicker-time__input-container {
	display: inline-block;
}

.react-datepicker-time__input {
	display: inline-block;
	margin-left: 10px;
}
input {
	width: auto;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='time'] {
	-moz-appearance: textfield;
}

/* TIME PICKER CSS */

.react-datepicker__triangle {
	left: 35px;
}

.react-datepicker__time-container {
	border-left: 0;
}

.react-datepicker__time,
.react-datepicker__time-box {
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker-time__delimiter {
	margin-left: 5px;
	display: inline-block;
}

.react-datepicker__time-container {
	float: right;
	border-left: 1px solid $datepicker__border-color;
	width: 100px;
}

.react-datepicker__time {
	position: relative;
	background: white;
	border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-box {
	width: 100%;
	overflow-x: hidden;
	margin: 0 auto;
	text-align: center;
	border-bottom-right-radius: 0.3rem;
}

ul.react-datepicker__time-list {
	list-style: none;
	margin: 0;
	height: 200px;
	overflow-y: scroll;
	padding-right: 0;
	padding-left: 0;
	width: 100px;
	box-sizing: content-box;
}

li.react-datepicker__time-list-item {
	height: 30px;
	padding: 5px 10px;
	white-space: nowrap;
}

li.react-datepicker__time-list-item:hover {
	cursor: pointer;
	background-color: #ffe1a8;
	font-weight: bold;
	color: #000;
}

li.react-datepicker__time-list-item--selected {
	background-color: #ffe1a8;
	color: #000;
	font-weight: bold;
}

li.react-datepicker__time-list-item--selected:hover {
	background-color: #ffe1a8;
}

li.react-datepicker__time-list-item--disabled {
	color: #ccc;
	background: transparent;
}

li.react-datepicker__time-list-item--disabled:hover {
	cursor: no-drop;
	background-color: transparent;
}

.react-datepicker__time-box:hover {
	cursor: pointer;
	background-color: #fff;
}

.react-datepicker__time-box--selected {
	background-color: #ffe1a8;
	color: #000;
	font-weight: bold;
}

.react-datepicker__time-box--selected:hover {
	background-color: #ffe1a8;
}

.react-datepicker__time-box--disabled {
	cursor: no-drop;
	color: #ccc;
}
